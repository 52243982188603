<template>
<body style="background-color: #f4f4f4; margin: 0 !important; padding: 0 !important;">
    <!-- HIDDEN PREHEADER TEXT -->
    <div style="display: none; font-size: 1px; color: #fefefe; line-height: 1px; font-family: 'Lato', Helvetica, Arial, sans-serif; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden;"> We're thrilled to have you here! Get ready to dive into your new account. </div>
    <table border="0" cellpadding="0" cellspacing="0" width="100%" height="100%">
        <tr>
            <td bgcolor="#09b585" align="center" style="padding: 50px 10px 0px 10px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                    <tr>
                        <td bgcolor="#ffffff" align="center" valign="top" style="padding: 40px 20px 20px 20px; border-radius: 4px 4px 0px 0px; color: #111111; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 48px; font-weight: 400; letter-spacing: 4px; line-height: 48px;">
                            <h1 style="font-size: 48px; font-weight: 400; margin: 2;">Welcome!</h1> <img src=" https://img.icons8.com/clouds/100/000000/handshake.png" width="125" height="120" style="display: block; border: 0px;" />
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td bgcolor="#09b585" align="center" style="padding: 0px 10px 50px 10px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px; height: 390px">
                    <tr>
                        <td bgcolor="#ffffff" align="left" style="padding: 20px 30px 40px 30px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;">
                            <p style="margin: 0; text-align: center;">We're excited to have you get started. First, you need to confirm your account. Just press the button below.</p>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#ffffff" align="left">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td bgcolor="#ffffff" align="center" style="padding: 20px 30px 60px 30px;">
                                        <table border="0" cellspacing="0" cellpadding="0">
                                            <tr>
                                                <td class="os-dropdown-trigger" align="center" style="border-radius: 3px;" bgcolor="#09b585">
                                                    <div @click="comfirmEmail()" target="_blank" style="font-size: 20px; font-family: Helvetica, Arial, sans-serif; color: #ffffff; text-decoration: none; color: #ffffff; text-decoration: none; padding: 15px 25px; border-radius: 2px; border: 1px solid #09b585; display: inline-block;">Confirm Account</div></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <!-- Loading -->
    <div v-if="isLoading">
        <Loading></Loading>
    </div>
</body>
</template>

<script>
import * as Logs from '@/utils/Logs'
import * as Utils from '@/utils/Utils'
import ApiManager from "@/api/ApiManager";
import * as Session from "@/utils/SessionStore";
import {
    COMFIRM_EMAIL,
    CHECK_TOKEN_REGISTER
} from "@/store/actionsType";
export default {
    name: "Confirmemail",
    data() {
        return {
            isLoading: false,
        };
    },
    mounted() {
        Logs.json("===>", this.$route.query)
    },
    created: function () {
        //this.checkCodeEncode()
    },
    methods: {
        checkCodeEncode(){
            this.$root.$refs.AppCyrus.start()
            var dataRequest = {"userId": this.$route.query.userId, "code": this.$route.query.code}
            this.$store.dispatch(CHECK_TOKEN_REGISTER, dataRequest)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    Logs.json('data', response);
                    if(!response.result){
                        this.$router.push({
                            path: "/link-expired"
                        });
                    }
                    else {
                        Utils.toastSuccess("Your account has been activated successfully");
                    }
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                })
        },

        async comfirmEmail() {
            this.isLoading = true
            
            try {
                var dataRequest = {"userId": this.$route.query.userId, "code": this.$route.query.code};
                Logs.logDebug("comfirmEmail", dataRequest);
                const response = await this.$store.dispatch(COMFIRM_EMAIL, dataRequest);

                Utils.toastSuccess(response.result);

                if (response && response.userRole === "EMPLOYEE"){
                    var resetPasswordLink = ApiManager.getUrlDomainUI() + response.resetPasswordLink;
                    Session.set("resetPasswordLink", resetPasswordLink);
                    var callbackUrl = Session.get("registrationLink") ? Session.get("registrationLink") : resetPasswordLink;
                    window.location.href = callbackUrl;
                }
                else {
                    this.$router.push({ 
                        path: `/login`
                    });
                }
            }
            catch (error) {
                console.log("Error in comfirmEmail", error);
                Logs.json("Error: ", error);
            }
            finally {
                this.isLoading = false
            }
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen {
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff) format('woff');
    }

    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v11/qdgUG4U09HnJwhYI-uK18wLUuEpTyoUstqEm5AMlJo4.woff) format('woff');
    }

    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
        src: local('Lato Italic'), local('Lato-Italic'), url(https://fonts.gstatic.com/s/lato/v11/RYyZNoeFgb0l7W3Vu1aSWOvvDin1pK8aKteLpeZ5c0A.woff) format('woff');
    }

    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 700;
        src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url(https://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYELO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
    }
}

/* CLIENT-SPECIFIC STYLES */
body,
table,
td,
a {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

table,
td {
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
}

img {
    -ms-interpolation-mode: bicubic;
}

/* RESET STYLES */
img {
    border: 0;
    height: auto;
    line-height: 100%;
    outline: none;
    text-decoration: none;
}

table {
    border-collapse: collapse !important;
}

body {
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
}

.wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* iOS BLUE LINKS */
a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

/* MOBILE STYLES */
@media screen and (max-width:600px) {
    h1 {
        font-size: 32px !important;
        line-height: 32px !important;
    }
}

/* ANDROID CENTER FIX */
div[style*="margin: 16px 0;"] {
    margin: 0 !important;
}
</style>
